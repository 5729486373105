/**
 * Consent aware utility for pushing to the dataLayer.
 * Ensures important events will not be lost before consent
 * is set.
 *
 * @param {object} content - Object for DL
 * @param {boolean} requireConsent - Should this wait for OneTrust (almost always yes)
 */
export function pushDataLayer(content, requireConsent = true) {
  if (typeof window === "undefined") {
    console.warn("pushDataLayer should not be run serverside");
    return;
  }

  if (requireConsent) {
    window.consentQueue.push(({ isFirstRun }) => {
      if (isFirstRun) window.dataLayer.push(content);
    });
  } else {
    window.dataLayer.push(content);
  }
}
